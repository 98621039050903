import React, { useEffect } from 'react'
import { Box, Heading, Text } from 'grommet'
import { parse } from 'qs'

import AppLayout from '../components/app-layout'

export default ({ location }) => {
  useEffect(() => {
    const params = parse(location.search.replace(/\?/g, ''))
    window.location.href = `https://gini.page.link/?link=https://my.ginihealth.com/deeplinks/fitbitCode?${params.code}&apn=com.healthbit.gini&amv=185&isi=1353057727&ibi=com.healthbit.gini&st=Gini+Health&sd=Personalized+health+and+nutrition&si=https://ginihealth.com`
  },[])
  return (
    <AppLayout page="reset">
      <Box
        style={{ maxWidth: '48rem', marginLeft: 'auto', marginRight: 'auto' }}
        fill={true}
        pad="xlarge"
      >
        <Heading color="accent-2">Fitbit Connectivity</Heading>
        <Text>Redirecting...</Text>
      </Box>
    </AppLayout>
  )
}
